<template>
	<DataTable
		:value="tableData"
		:filters="filters"
		:globalFilterFields="globalFilterFields"
		@row-click="onRowClicked($event)"
		filterDisplay="row"
		tableStyle="min-width: 50rem"
		scrollHeight="500px">
		<template #header>
			<div
				class="flex flex-wrap align-items-center justify-content-between gap-2">
				<span class="text-xl text-900 font-bold">{{
					`${tableTitle} (${tableData ? tableData.length : 0})`
				}}</span>
				<IconField>
					<InputText
						placeholder="Recherche"
						v-model="searchText"
						@input="search(searchText)" />
				</IconField>
			</div>
		</template>
		<Column
			v-for="col of columns"
			:field="col.field"
			:header="col.header"
			:key="col.field">
			<template
				#body="slotProps"
				v-if="col.field === 'actions'">
				<Button
					severity="help"
					@click="declaration(slotProps.data)"
					icon="pi pi-pencil"
					rounded
					raised />
			</template>
			<template
				#body="slotProps"
				v-else-if="col.field === 'created_at'">
				{{ formatLocaleDate(slotProps.data.created_at) }}
			</template>
			<template
				#body="slotProps"
				v-else-if="col.field === 'mother_last_name'">
				{{
					slotProps.data.mother_last_name +
					" " +
					slotProps.data.mother_first_name
				}}
			</template>
			<template
				#body="slotProps"
				v-else-if="col.field === 'last_name_deceased'">
				{{
					slotProps.data.last_name_deceased +
					" " +
					slotProps.data.first_name_deceased
				}}
			</template>
			<template
				#body="slotProps"
				v-else-if="col.field === 'declarant'">
				{{ agentName(slotProps.data.declarant) }}
			</template>
			<template
				#body="slotProps"
				v-else-if="col.field === 'document'">
				<Button
					class="p-2"
					severity="success"
					@click.prevent="openInNewBlank(slotProps.data)"
					rounded
					raised>
					<span
						class="material-icons"
						style="font-size: 18px"
						>print</span
					>
				</Button>
			</template>
		</Column>
	</DataTable>
</template>

<script lang="ts">
	import { defineComponent } from "vue";
	import { DeathDeclaration } from "@/models/declaration/death-declaration";
	import { espacePartenaireStore } from "@/store/modules/espace-partenaire";
	import { agentStore } from "@/store/modules/agent";
	import { dataMixin } from "@/mixins/data.mixins";
	import { FilterMatchMode } from "primevue/api";
	import { dateMixin } from "@/mixins/date.mixins";
	import { usePrintStore } from "@/store/modules/print-process";
	import moment from 'moment';

	export default defineComponent({
		name: "TableView",
		props: [
			"loading",
			"dataKey",
			"data",
			"columns",
			"tableTitle",
			"globalFilterFields",
		],
		components: {},
		mixins: [dataMixin, dateMixin],
		data() {
			return {
				selectedItem: null,
				declarationUpdate: false,
				filters: {
					last_name_deceased: {
						value: null,
						matchMode: FilterMatchMode.CONTAINS,
					},
				},
				searchText: "",
				tableData: this.data,
				partnerStore: espacePartenaireStore(),
				agentStore: agentStore(),
			};
		},
		computed: {},
		watch: {
			data() {
				this.tableData = this.data;
			},
		},
		methods: {
			onRowSelect(item) {
				this.$emit("onTableRowClicked", item);
			},
			declaration(item) {
				this.agentStore.setAgent(item);
				this.$emit("declarationUpdate", !this.declarationUpdate);
				this.declarationUpdate = !this.declarationUpdate;
				return "";
			},
			openInNewBlank(payload: DeathDeclaration) {
				if(payload.birth_id) this.printPage(payload, 'birth')
				else this.printPage(payload, 'death')
			},
			printPage(data: DeathDeclaration, type: string) {
				let payload: any = data;
				payload.district = espacePartenaireStore().getHealthCenter.district,
				payload.township = espacePartenaireStore().getHealthCenter.township,
				payload.health_center_name = data.health_center;
				payload.bp = espacePartenaireStore().getHealthCenter.postal_code;
				payload.center_phone = espacePartenaireStore().getHealthCenter.mobile,
				payload.function = dataMixin.methods.agentFunction(data.midwife ? data.midwife : data.chief_service);
				payload.service = dataMixin.methods.agentService(data.midwife ? data.midwife : data.chief_service)
				payload.mother_lastname = data.mother_last_name;
				payload.mother_firstname = data.mother_first_name;
				payload.created_at = dateMixin.methods.formatDate(data.created_at);
				payload.town = espacePartenaireStore().getHealthCenter.town,
				payload.sex = payload.sex == 'male' ? 'Masculin' : 'Feminin'
				payload[type === 'death' ? 'death_date' : 'birth_date'] = dateMixin.methods.formatDate(data[type === 'death' ? 'deathdate' : 'birthdate']);
				if(type === 'death') {
					payload.defunt_age = data.age_deceased;
					payload.defunt_function = data.function_of_deceased;
					payload.defunt_name = data.last_name_deceased + " " + data.first_name_deceased
				}
				Object.keys(payload).forEach((key) => {
					if(key.includes('midwife') || key.includes('chief_service') || key.includes('declarant')) {
						payload[key] = dataMixin.methods.agentName(payload[key])
					}
				});
				const windowPrint = window.open('', '', 'width=800,height=600');
				windowPrint?.document.write(this.processTemplate(
					type === 'death' ? usePrintStore().getDeathPrintPage : usePrintStore().getBirthPrintPage, 
					{
						...payload
					}
				));
				windowPrint?.document.close();
				windowPrint?.focus();
				windowPrint?.print();
			},
			processTemplate(template: string, variables: Record<string, string>){
				return template.replace(/{{\s*(\w+)\s*}}/g, (_, variable) => {
					return variables[variable] || '';
				});
			},
			onRowClicked(item: any) {
				this.$emit("onTableRowClicked", item.data);
				this.$parent?.$emit("onTableRowClicked", item.data);
			},
			search(searchValue) {
				this.tableData = this.data.filter((item) => {
					const value = searchValue.toLowerCase();
					return (
						item.health_center?.toLowerCase().includes(value) ||
						item.tracked_code?.toLowerCase().includes(value) ||
						item.mother_last_name?.toLowerCase().includes(value) ||
						item.mother_first_name?.toLowerCase().includes(value) ||
						item.problem?.toLowerCase().includes(value) ||
						item.first_name_deceased?.toLowerCase().includes(value) ||
						item.service_name?.toLowerCase().includes(value) ||
						item.name?.toLowerCase().includes(value)
					);
				});
			},
		},
	});
</script>
